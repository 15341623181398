<template>
  <div>
    <v-container
      grid-list-xl>
      <v-layout
        fluid
        pt-0
        wrap>
        <v-flex xs12>
          <app-card
            :footer="true"
            heading="Region information"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <div>
              <rotate-square2 v-if="loading" />
              <v-container
                v-else
                grid-list-xl>
                <v-layout
                  row
                  wrap>
                  <v-flex
                    xs12
                    sm6>
                    <v-text-field
                      v-model="region.name"
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      :rules="[v => Boolean(v) || 'Please provide a name']"
                      label="Name"
                    />
                  </v-flex>
                  <v-flex xs-12>
                    <v-combobox
                      v-model="cities"
                      :search-input.sync="search"
                      multiple
                      label="Cities"
                      append-icon
                      chips
                      deletable-chips
                      class="tag-input"
                      @keyup.tab="updateTags"
                      @paste="updateTags"/>
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
            <template slot="footer">
              <v-btn
                color="primary"
                class="mr-3"
                @click="saveFunction()"
              >Save</v-btn>
            </template>
          </app-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Request from '../../../../helpers/request'
import { TEXT_FIELD_MAX_LENGTH } from '../../../../constants/common'

export default {
  mixins: [Request],
  data () {
    return {
      TEXT_FIELD_MAX_LENGTH,
      loading: true,
      region: {},
      cities: [],
      search: ''
    }
  },
  async beforeMount () {
    this.requestData()
  },
  methods: {
    requestData () {
      this.request('GET', `/regions/${this.$route.params.id}`, {}, ({ data }) => {
        this.region = data
        this.cities = data.cities
      }, (loader) => { this.loading = loader })
    },
    saveFunction () {
      let body = {
        data: {
          name: this.region.name,
          cities: this.cities
        }
      }

      this.request('PATCH', `/regions/${this.$route.params.id}`, body, ({ data }) => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Region is updated'
        })

        this.$router.push('/tables/regions')
      })
    },
    updateTags () {
      this.$nextTick(() => {
        this.cities && this.cities.push(...this.search.split(','))
        this.$nextTick(() => {
          this.search = ''
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .tag-input span.chip {
    background-color: #1976d2;
    color: #fff;
    font-size: 1em;
  }

  .tag-input span.v-chip {
    background-color: #1976d2;
    color: #fff;
    font-size:1em;
    padding-left:7px;
  }

  .tag-input span.v-chip {
    &:before {
      content: "label";
      font-size: 20px;
      display: inline-block;
      white-space: nowrap;
    }
  }
</style>
